
    <div
      *ngFor="let user of userList | slice:0:avatarMax"
      class="fw-avatar fw-avatar-25 fw-avatar-margin-right-5 animated zoomInSeq"
      [style.background]="user.avatar ? 'url(' + user.avatar + ')' : '#' + user.avatarColor"
      [style.backgroundSize]="'cover'"
      [matTooltip]="user.info"
      [matTooltipDisabled]="!enablePopover"
      matTooltipPosition="above">
      {{!user.avatar ? user.initials : ''}}
    </div>

    <span style="padding:5px 0;margin:0" *ngIf="userList.length > avatarMax && userList.length !== 1"
      class="fw-avatar fw-avatar-25 fw-avatar-more clickable"
      [matTooltip]="extraUserString"
      matTooltipClass="tooltip-multiline"
      matTooltipPosition="left">
      +{{userList.length - avatarMax}}
    </span>
  