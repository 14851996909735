/* eslint-disable @typescript-eslint/no-explicit-any */
import { Profile } from './shared/models/profile.model';

export abstract class ApiConfig {
  auth: {
    cas: {[key: string]: any}
    google: {[key: string]: any}
    ldap: {[key: string]: any}
    saml: {[key: string]: any}
    wechat: {[key: string]: any}
  }
  created: string
  features: {
    [name: string]: boolean | string
  }
  modified: string
  signed_url: boolean
  site: {[key: string]: any}
}

export function apiConfigFactory($injector: any): ApiConfig {
  return $injector.get('apiConfig');
}

export const apiConfigProvider = {
  provide: ApiConfig,
  useFactory: apiConfigFactory,
  deps: ['$injector'],
};

export abstract class FlywheelConfig {
  roles: {
    id: string
    name: string
  }[]
}

export function flywheelConfigFactory($injector: any): FlywheelConfig {
  return $injector.get('flywheelConfig');
}

export const flywheelConfigProvider = {
  provide: FlywheelConfig,
  useFactory: flywheelConfigFactory,
  deps: ['$injector'],
};

export abstract class FlywheelService {
  sessions: angular.resource.IResource<any>
  subjects: angular.resource.IResource<any>
  projects: angular.resource.IResource<any>
  acquisitions: angular.resource.IResource<any>
  collections: angular.resource.IResource<any>
  tickets: angular.resource.IResource<any>
  download: angular.resource.IResource<any>
  files: angular.resource.IResource<any>
  users: angular.resource.IResource<any>
}

export function flywheelServiceFactory($injector: any): FlywheelService {
  return $injector.get('flywheelFactory');
}

export const flywheelServiceProvider = {
  provide: FlywheelService,
  useFactory: flywheelServiceFactory,
  deps: ['$injector'],
};

export abstract class ProfileService {
  isGroupAdmin: boolean
  profile: Profile
  promise: angular.IPromise<Profile>
  loginType: any

  // functions
  abstract canAdminCollection(collection: any): boolean
  abstract canAdminGroup(group: any): boolean
  abstract canAdminProject(project: any): boolean
  abstract canCreateGroup(): boolean
  abstract canManage(): boolean
  abstract canWriteToGroup(group: any): boolean
  abstract clear(): angular.IPromise<undefined>
  abstract generateAPIKey(): angular.IPromise<{key: string}>
  abstract getAvatarType(): angular.IPromise<any>
  abstract getProfile(resetProfile?: boolean): angular.IPromise<Profile>
  abstract putProfile(data: Profile): angular.IPromise<undefined>
  abstract getRoleMatrix(container: any, containerType: any): angular.IPromise<any>
  abstract getAvatarColor(user: any): string
  abstract getIsGroupAdmin(): angular.IPromise<boolean>
}

export function profileServiceFactory($injector: any): ProfileService {
  return $injector.get('profileFactory');
}

export const profileServiceProvider = {
  provide: ProfileService,
  useFactory: profileServiceFactory,
  deps: ['$injector'],
};

/* Provided by satellizer */
export abstract class AuthService {
  abstract getToken(): string
  abstract isAuthenticated(): boolean
}

export function authServiceFactory(i: any): AuthService {
  return i.get('$auth');
}

export const authProvider = {
  provide: AuthService,
  useFactory: authServiceFactory,
  deps: ['$injector'],
};

export abstract class DashboardService {
  abstract getSessions(): Promise<any[]>
  abstract setSessions(promise: any): void
}

export function dashboardServiceFactory($injector: any): DashboardService {
  return $injector.get('dashboardFactory');
}

export const dashboardServiceProvider = {
  provide: DashboardService,
  useFactory: dashboardServiceFactory,
  deps: ['$injector'],
};

export abstract class NotificationService {
  abstract error(input: string, isRawMessage?: boolean): void
  abstract success(input: string, isRawMessage?: boolean): void
  abstract warning(input: string, isRawMessage?: boolean): void
}

export function notificationServiceFactory($injector: any): NotificationService {
  return $injector.get('notificationsFactory');
}

export const notificationServiceProvider = {
  provide: NotificationService,
  useFactory: notificationServiceFactory,
  deps: ['$injector'],
};

export abstract class UIRouterState {
  abstract go(state: string, params?: object): void
}

export function uiRouterStateFactory($injector: any): UIRouterState {
  return $injector.get('$state');
}

export const uiRouterStateProvider = {
  provide: UIRouterState,
  useFactory: uiRouterStateFactory,
  deps: ['$injector'],
};
