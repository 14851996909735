/* eslint-disable @typescript-eslint/no-namespace, no-redeclare, no-inner-declarations */

import { User } from '../models/user.model';

export enum SiteRole {
  Public = 'public',
  User =  'user',
  Developer = 'developer',
  SiteAdmin = 'site_admin',
  Drone = 'drone',
}

export namespace SiteRole {
  export function getPrimaryRole(user: User): SiteRole {
    if (user.roles instanceof Array) {
      const roles = new Set(user.roles);
      if (roles.has(SiteRole.SiteAdmin)) {
        return SiteRole.SiteAdmin;
      }
      if (roles.has(SiteRole.Developer)) {
        return SiteRole.Developer;
      }
      if (roles.has(SiteRole.User)) {
        return SiteRole.User;
      }
      return SiteRole.Public;
    }
    // fall back to `root` flag
    return user.root ? SiteRole.SiteAdmin : SiteRole.User;
  }

  export function isSiteAdmin(user: User): boolean {
    return getPrimaryRole(user) === SiteRole.SiteAdmin;
  }
}
