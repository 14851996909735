import { formatDate } from '@angular/common';

import { defaultLocale } from '../app.constants';
import { Device } from './models/device.model';
import { File } from './models/file.model';
import { User } from './models/user.model';

interface JoinOrigin {
  device: {
    [id: string]: Device
  }
  job: {
    [id: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
  }
  user: {
    [id: string]: User
  }
}

interface OriginInfo {
  class: string
  description: string
  bidsDescription: string
  popoverText: string
}

export function getFileClassification(file: File): string {
  const modality = file.modality || 'N/A';
  const dimensions: string[] = [];
  const dimensionSet = new Set<string>();
  for (const classification of Object.values(file.classification || {})) {
    for (const dimension of classification) {
      if (!dimension) {
        continue;
      }
      const lowered = dimension.toLowerCase();
      if (!dimensionSet.has(lowered)) {
        dimensionSet.add(lowered);
        dimensions.push(dimension);
      }
    }
  }
  if (dimensions.length > 0) {
    return `${modality}: ${dimensions.join(', ')}`
  }
  return modality;
}

export function getOriginInfo(file: File, origins: JoinOrigin): OriginInfo {
  const fileName = `File: ${file.name}`;
  const fileSize = `Size: ${(file.size / 1024 / 1024).toFixed(2)} MB`;
  const zipFileCount = file.zip_member_count ? `File count in zip: ${file.zip_member_count}` : '';
  const fileBIDSDescription = file.info.BIDS ? file.info.BIDS.Filename : '';

  try {
    const originType = origins[file.origin.type] || {};
    const origin = originType[file.origin.id] || { name: file.origin.name || file.origin.id };
    switch (file.origin.type) {
      case 'job':
        return {
          class: 'fa-cog',
          description: file.name,
          bidsDescription: fileBIDSDescription,
          popoverText: [
            fileName,
            fileSize,
            `Initiated: ${formatDate(origin.created, 'short', defaultLocale)}`,
            `Completed: ${formatDate(origin.modified, 'short', defaultLocale)}`,
            `Gear: ${origin.gear_name}`,
            `Gear Version: ${origin.gear_info.version}`,
            zipFileCount,
          ].filter(Boolean).join('\n'),
        };
      case 'device':
        return {
          class: 'fa-circle-thin',
          description: file.name,
          bidsDescription: fileBIDSDescription,
          popoverText: [
            fileName,
            fileSize,
            `Uploaded: ${formatDate(file.created, 'short', defaultLocale)}`,
            file.replaced ? `Replaced: ${formatDate(file.replaced, 'short', defaultLocale)}` : null,
            `Device: ${origin.name}`,
            zipFileCount,
          ].filter(Boolean).join('\n'),
        };
      case 'user':
        return {
          class: 'fa-user',
          description: file.name,
          bidsDescription: fileBIDSDescription,
          popoverText: [
            fileName,
            fileSize,
            `Uploaded: ${formatDate(file.created, 'short', defaultLocale)}`,
            file.replaced ? `Replaced: ${formatDate(file.replaced, 'short', defaultLocale)}` : null,
            `User: ${origin.firstname} ${origin.lastname}`,
            zipFileCount,
          ].filter(Boolean).join('\n'),
        };
      default:
        return {
          class: 'fa-question-circle',
          description: file.name,
          bidsDescription: fileBIDSDescription,
          popoverText: '',
        };
    }
  } catch (error) {
    return {
      class: '',
      description: '',
      bidsDescription: '',
      popoverText: '',
    };
  }
}
