declare const angular: angular.IAngularStatic;

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { downgradeComponent } from '@angular/upgrade/static';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import APP_MODULE from '../app.module.ajs';
import { apiConfigProvider, flywheelConfigProvider, profileServiceProvider } from '../ajs-upgraded-providers';
import { FwAvatarComponent } from './fw-avatar/fw-avatar.component';
import { FwInputComponent } from './fw-input/fw-input.component';
import { FwSelectComponent } from './fw-select/fw-select.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
  declarations: [
    FwAvatarComponent,
    FwInputComponent,
    FwSelectComponent,
  ],
  entryComponents: [
    FwAvatarComponent,
    FwInputComponent,
    FwSelectComponent,
  ],
  providers: [
    apiConfigProvider,
    flywheelConfigProvider,
    profileServiceProvider,
  ]
})
export class DirectivesModule { }

angular.module(APP_MODULE)
  .directive(
    'fwAvatar',
    downgradeComponent({ component: FwAvatarComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwInput',
    downgradeComponent({ component: FwInputComponent }) as angular.IDirectiveFactory
  )
  .directive(
    'fwSelect',
    downgradeComponent({ component: FwSelectComponent }) as angular.IDirectiveFactory
  );
