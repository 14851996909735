export function getContainerType(container: {[key: string]: string} = {}): string {
  if (container.session) {
    return 'acquisition';
  }
  if (container.subject) {
    return 'session';
  }
  if (container.project) {
    return 'subject';
  }
  if (container.group) {
    return 'project';
  }
  if (container.curator) {
    return 'collection';
  }
  if (container.job) {
    return 'analysis';
  }
  throw new Error('Cannot determine container type.');
}

export function pluralize(containerType: string): string {
  if (containerType === 'analysis') {
    return 'analyses';
  }
  return containerType + 's';
}
