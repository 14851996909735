import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';

import { AdminModule } from './admin/admin.module';
import { authProvider, notificationServiceProvider, uiRouterStateProvider } from './ajs-upgraded-providers';
import APP_MODULE from './app.module.ajs';
import { DirectivesModule } from './common/directives.module';
import { httpInterceptorProviders } from './http-interceptors';
import { ProfileService } from './profile/profile.service';
import { SearchModule } from './search/search.module';
import { DialogModule } from './shared/dialogs/dialog.module';
import { ConfigService } from './shared/config.service';
import { SidebarModule } from './sidebar/sidebar.module';

@NgModule({
  imports: [
    // The order of Angular modules can be important
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UpgradeModule,

    // Our modules
    AdminModule,
    DialogModule,
    DirectivesModule,
    SearchModule,
    SidebarModule,
  ],
  providers: [
    authProvider,
    httpInterceptorProviders,
    notificationServiceProvider,
    uiRouterStateProvider,
    ConfigService,
    ProfileService,
  ],
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap(): void {
    this.upgrade.bootstrap(document.body, [APP_MODULE], { strictDi: true });
  }
}

declare const angular: angular.IAngularStatic;
