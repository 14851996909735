import { Injectable } from "@angular/core";
import { downgradeInjectable } from '@angular/upgrade/static';

import { ApiConfig } from '../ajs-upgraded-providers';
import APP_MODULE from '../app.module.ajs';
import { queryStringToParams } from './url.utils';

interface Features {
  [name: string]: boolean | string
}

/**
 * These are features that don't require explicit configuration in order to be enabled.
 */
const standardFeatures: Features = {}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public features: Features

  constructor(private apiConfig: ApiConfig) {
    // Standard Features <- API Config <- Query Parameters
    this.features = Object.assign({}, standardFeatures, apiConfig.features, queryStringToParams(window.location.search, coerceBoolean));
  }

  getFeatureSetting(name: string): boolean | string {
    return this.features[name];
  }

  isFeatureEnabled(name: string): boolean {
    return Boolean(this.getFeatureSetting(name));
  }
}

function coerceBoolean(value = ''): boolean | string {
  switch (value.toLowerCase()) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return value;
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'configService',
    downgradeInjectable(ConfigService)
  );
