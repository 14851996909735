import { NgModule } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

import { dashboardServiceProvider } from '../ajs-upgraded-providers';
import { SidebarService } from './sidebar.service';

@NgModule({
  providers: [
    dashboardServiceProvider,
    SidebarService,
  ],
})
export class SidebarModule { }

declare const angular: angular.IAngularStatic;
angular
  .module('sidebar', [])
  .factory(
    'sidebarFactory',
    downgradeInjectable(SidebarService)
  );
