import 'core-js/es6';
import 'core-js/es7/reflect';
import 'core-js/es7/object';
import 'zone.js';
import 'hammerjs';

if (!Element.prototype.matches) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  Element.prototype.matches = (<any>Element).prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
