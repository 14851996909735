import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, map } from 'rxjs/operators';

import { FlywheelService } from '../flywheel.service';
import { RequestState, isLoading, isInitialized } from '../shared/enums/request-state.enum';
import { Device } from '../shared/models/device.model';

@Injectable()
export class AdminService {
  private devices = {
    state: RequestState.Empty,
    subject: new BehaviorSubject<Device[]>(undefined),
  }
  readonly devices$ = this.devices.subject.asObservable()

  private error = new Subject<Error>()
  readonly error$ = this.error.asObservable()

  constructor(private flywheel: FlywheelService) { }

  getDevices({ refresh = false } = {}): Observable<Device[]> {
    if (isLoading(this.devices.state)) {
      return this.devices$;
    }
    if (!isInitialized(this.devices.state) || refresh) {
      this.devices.state = RequestState.Fetching;
      this.flywheel.devices.query({ filter: 'type=~reaper|importer' }).subscribe(data => {
        this.devices.subject.next(data);
      }, error => {
        this.error.next(error);
      }, () => {
        this.devices.state = RequestState.Loaded;
      });
    }
    return this.devices$;
  }

  addDevice(body: Partial<Device>): Observable<Device> {
    const observable = this.flywheel.devices.post(body).pipe(
      shareReplay(1),
    );
    observable.subscribe(() => {
      this.getDevices({ refresh: true });
    }, () => {});
    return observable;
  }

  updateDevice(deviceId: string, body: {disabled: boolean}): Observable<null> {
    const observable = this.flywheel.devices.put(deviceId, body).pipe(
      shareReplay(1),
    );
    observable.subscribe(() => {
      this.getDevices({ refresh: true });
    }, () => {});
    return observable;
  }

  getDeviceInfo(deviceId: string): Observable<Device> {
    return this.flywheel.devices.get(deviceId);
  }

  generateDeviceKey(deviceId: string): Observable<string> {
    const observable = this.flywheel.devices.generateKey(deviceId).pipe(
      map(({ key }) => key),
      shareReplay(1),
    )
    observable.subscribe(() => {
      this.getDevices({ refresh: true });
    }, () => {});
    return observable;
  }
}
