import { Component } from '@angular/core';
import { MatDialog, Sort } from '@angular/material';
import _ from 'lodash';

import { AddDeviceComponent } from './add-device.component';
import { AdminService } from '../admin.service';
import { NotificationService } from '../../ajs-upgraded-providers';
import { ConfirmDialog } from '../../shared/dialogs/confirm-dialog.component';
import { Device } from '../../shared/models/device.model';
import { SidebarService } from '../../sidebar/sidebar.service';

@Component({
  selector: 'device-settings',
  templateUrl: './device-settings.component.html',
  styles: [`
    .action-buttons {
      float: right;
    }
    .api-key {
      color: #666;
    }
    .data-table {
      margin: 15px;
      width: calc(100% - 30px) !important;
    }
    .delete-icon {
      color: #999 !important;
    }
  `],
})
export class DeviceSettingsComponent {
  public deviceColumns = ['type', 'label', 'last_seen', 'key', 'disabled']
  public filteredDevices: Device[] = []
  public labelFilter = ''
  public visibleKey: {[id: string]: string} = {}

  private devices: Device[] = []
  private sort: Sort = { active: 'last_seen', direction: 'desc' }

  constructor(
    private admin: AdminService,
    private dialog: MatDialog,
    private notification: NotificationService,
    sidebar: SidebarService,
  ) {
    sidebar.link.active = 'devices';
  }

  ngOnInit(): void {
    this.admin.getDevices({ refresh: true }).subscribe(devices => {
      this.devices = devices || [];
      this.sortDevices();
    });
  }

  sortDevices(sort: Sort = this.sort): void {
    this.sort = sort;
    if (sort.active === 'label') {
      this.devices = _.sortByOrder(this.devices, ({ label = '' }) => label.toLowerCase(), [sort.direction]);
    } else {
      this.devices = _.sortByOrder(this.devices, [sort.active], [sort.direction]);
    }
    if (sort.active === 'last_seen') {
      const [wasSeen, notSeen] = _.partition(this.devices, device => device.last_seen);
      this.devices = sort.direction === 'desc' ? [...wasSeen, ...notSeen] : [...notSeen, ...wasSeen];
    }
    this.filterDevices();
  }

  filterDevices(): void {
    this.filteredDevices = this.devices.filter(device => device.label.includes(this.labelFilter));
  }

  /**
   * mat-slide-toggle needs to observe `disabled` as having been changed in order to re-align its state.
   * Alternatively we could use ngModel, but we'd have to flip the value.
   */
  blitDevice(device: Device): void {
    const disabledState = device.disabled;
    device.disabled = !disabledState;
    setTimeout(() => {
      device.disabled = disabledState;
    });
  }

  addDevice(): void {
    this.dialog.open(AddDeviceComponent).afterClosed().subscribe((device: Device) => {
      // Notifications are handled by the modal
      // Falsy device means the modal was cancelled
      if (device) {
        this.visibleKey[device._id] = device.key;
      }
    });
  }

  revealKey(device: Device): void {
    this.visibleKey[device._id] = device.key;
    this.admin.getDeviceInfo(device._id).subscribe(info => {
      this.visibleKey[info._id] = info.key || '<none>';
    });
  }

  generateKey(device: Device): void {
    this.dialog.open(ConfirmDialog, {
      data: { content: 'Generate a new device key?' }
    }).afterClosed().subscribe(proceed => {
      if (proceed) {
        this.admin.generateDeviceKey(device._id).subscribe(key => {
          this.visibleKey[device._id] = key;
        }, () => {
          this.notification.error('device.key.generation.error');
          this.blitDevice(device);
        });
      } else {
        this.blitDevice(device);
      }
    });
  }

  disableDevice(device: Device): void {
    this.dialog.open(ConfirmDialog, {
      data: { content: 'Disable this device and revoke its API key?' }
    }).afterClosed().subscribe(proceed => {
      if (proceed) {
        this.admin.updateDevice(device._id, { disabled: true }).subscribe(() => {
          this.visibleKey[device._id] = '<none>';
        }, () => {
          this.notification.error('device.disable.error');
          this.blitDevice(device);
        });
      } else {
        this.blitDevice(device);
      }
    });
  }

  enableDevice(device: Device): void {
    this.dialog.open(ConfirmDialog, {
      data: { content: 'Enable this device and generate an API key?' }
    }).afterClosed().subscribe(proceed => {
      if (proceed) {
        this.admin.updateDevice(device._id, { disabled: false }).subscribe(() => {
          this.revealKey(device);
        }, () => {
          this.notification.error('device.enable.error');
          this.blitDevice(device);
        });
      } else {
        this.blitDevice(device);
      }
    });
  }
}
