import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { ConfirmDialog } from './confirm-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialog,
  ],
  entryComponents: [
    ConfirmDialog,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class DialogModule { }
