<header>
  <h1 class="font-page-header">Devices</h1>
</header>
<div class="tab-bar padded">
  <mat-form-field appearance="outline">
    <input data-id="devices-filter-label" matInput placeholder="Filter by Label" [(ngModel)]="labelFilter" (ngModelChange)="filterDevices()"/>
  </mat-form-field>
  <div class="action-buttons">
    <button data-id="devices-new-button" mat-raised-button color="primary" (click)="addDevice()">Add New Device</button>
  </div>
</div>
<table mat-table [dataSource]="filteredDevices" matSort (matSortChange)="sortDevices($event)" class="fw-table data-table mat-elevation-z1">
  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
    <td data-id="devices-type-text" mat-cell *matCellDef="let element">{{element.type | titlecase}}</td>
  </ng-container>
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Label</th>
    <td data-id="devices-label-text" mat-cell *matCellDef="let element">{{element.label}}</td>
  </ng-container>
  <ng-container matColumnDef="last_seen">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Seen</th>
    <td data-id="devices-date-text" mat-cell *matCellDef="let element">{{element.last_seen | date:'medium'}}</td>
  </ng-container>
  <ng-container matColumnDef="key">
    <th mat-header-cell *matHeaderCellDef>API Key</th>
    <td mat-cell *matCellDef="let element" width="20%">
      <ng-container *ngIf="!element.disabled && !visibleKey[element._id]">
        <code class="api-key">••••••••••••••••••</code>
        <div style="display:inline-block">
          <button data-id="devices-show-key" mat-icon-button (click)="revealKey(element)" matTooltip="Reveal Device Key" matTooltipPosition="above">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </div>
      </ng-container>
      <ng-container *ngIf="!element.disabled && visibleKey[element._id]">
        <code class="api-key">{{visibleKey[element._id]}}</code>
        <div style="display:inline-block">
          <button data-id="devices-regen-key" mat-icon-button (click)="generateKey(element)" matTooltip="Regenerate Device Key" matTooltipPosition="above">
            <mat-icon>cached</mat-icon>
          </button>
        </div>
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="disabled">
    <th mat-header-cell *matHeaderCellDef>Enabled</th>
    <td mat-cell *matCellDef="let element">
      <mat-slide-toggle data-id="devices-enable-toggle" [checked]="element.disabled !== true" (change)="element.disabled ? enableDevice(element) : disableDevice(element)"></mat-slide-toggle>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="deviceColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: deviceColumns;"></tr>
</table>
