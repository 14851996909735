import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'fw-select',
  template: `
    <mat-form-field appearance="outline">
      <mat-select 
        [(formControl)]="control"
        [multiple]="multiple"
        [placeholder]="placeholder"
        [required]="required" 
        (selectionChange)="valueChange.emit($event.value)">
        <mat-option 
          *ngFor="let option of options" 
          [value]="option.value || option.label">
          {{ option.label || option.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        This field is required.
      </mat-error>
    </mat-form-field>
  `
})

export class FwSelectComponent {
  @Input() options: {
    value: string,
    label: string,
  }[];
  @Input() placeholder = '';
  @Input() multiple = false; // whether or not you want it to be a multiselect input
  @Input() required = false;
  @Input() default = '';
  @Input() value: string[] | string;

  @Output() valueChange = new EventEmitter<string[] | string>();

  control: FormControl;
  validators: ((string) => {[code: string]: {message: string}})[];

  ngOnInit(): void {
    this.validators = [];
    if (!this.value) {
      this.value = this.multiple ? [] : '';
    }

    if (this.required) {
      this.validators.push(Validators.required);
    }

    let initialValue = this.value;
    // user specified default value
    if (this.value.length < 1 && this.default) {
      initialValue = this.default;
    }
    this.control = new FormControl(initialValue, this.validators);

    this.control.valueChanges.subscribe(this.valueChange);
  }

  ngOnChange(): void {
    if (this.value !== null && this.value !== this.control.value) {
      this.control.setValue(this.value);
    }
  }
}
