/* eslint-disable @typescript-eslint/no-namespace, no-redeclare, no-inner-declarations */

export enum ContainerAccess {
  ReadOnly = 'ro',
  ReadWrite = 'rw',
  Admin = 'admin',
}

export interface RoleMatrix {[access: string]: boolean}

export namespace ContainerAccess {
  const values = Object.freeze({
    [ContainerAccess.ReadOnly]: 1,
    [ContainerAccess.ReadWrite]: 2,
    [ContainerAccess.Admin]: 3,
  });

  export function valueOf(access: ContainerAccess): number {
    return values[access] || 0;
  }

  export function roleMatrix(maxAccess: number): RoleMatrix {
    return {
      [ContainerAccess.ReadOnly]: maxAccess >= values[ContainerAccess.ReadOnly],
      [ContainerAccess.ReadWrite]: maxAccess >= values[ContainerAccess.ReadWrite],
      [ContainerAccess.Admin]: maxAccess >= values[ContainerAccess.Admin],
    };
  }
}
