import { Component, HostListener, Input, OnInit } from '@angular/core';
import find from 'lodash/collection/find';
import isEmpty from 'lodash/lang/isEmpty';
import isString from 'lodash/lang/isString';
import partition from 'lodash/collection/partition';
import result from 'lodash/object/result';
import sortBy from 'lodash/collection/sortBy';

import { FlywheelConfig } from '../../ajs-upgraded-providers';
import { ProfileService, getAvatarColor } from '../../profile/profile.service';
import { User } from '../../shared/models/user.model';

interface AvatarUser extends User {
  access: string
  avatar?: string
  avatarColor: string
  info: string
  initials: string
  roleLabel: string
}

@Component({
  selector: 'fw-avatar',
  template: `
    <div
      *ngFor="let user of userList | slice:0:avatarMax"
      class="fw-avatar fw-avatar-25 fw-avatar-margin-right-5 animated zoomInSeq"
      [style.background]="user.avatar ? 'url(' + user.avatar + ')' : '#' + user.avatarColor"
      [style.backgroundSize]="'cover'"
      [matTooltip]="user.info"
      [matTooltipDisabled]="!enablePopover"
      matTooltipPosition="above">
      {{!user.avatar ? user.initials : ''}}
    </div>

    <span style="padding:5px 0;margin:0" *ngIf="userList.length > avatarMax && userList.length !== 1"
      class="fw-avatar fw-avatar-25 fw-avatar-more clickable"
      [matTooltip]="extraUserString"
      matTooltipClass="tooltip-multiline"
      matTooltipPosition="left">
      +{{userList.length - avatarMax}}
    </span>
  `
})
export class FwAvatarComponent implements OnInit {
  @Input()
  set users(users: User[] | User) {
    if (!Array.isArray(users)) {
      users = [users];
    }
    const sortedUsers = sortBy(users, 'lastname');
    const [adminUsers, nonAdminUsers] = partition(sortedUsers, user => user.access === 'admin');
    this.userList = [...adminUsers, ...nonAdminUsers].map((user: AvatarUser) => {
      const avatar = isString(user.avatar) && !user.avatar.startsWith('https') ? '' : user.avatar;
      const initials = !isEmpty(user.firstname) && !isEmpty(user.lastname) ? user.firstname[0] + user.lastname[0] : '';
      const roleLabel = result(find(this.flywheelConfig.roles, { id: user.access }), 'name');
      return {
        ...user,
        avatar,
        avatarColor: getAvatarColor(user),
        info: `${user.firstname} ${user.lastname} (${roleLabel})`,
        initials,
        roleLabel,
      };
    });
  }

  @Input() enablePopover = true
  @Input() hideCurrentUser = false

  public avatarMax: number = Infinity
  public extraUserString: string = ''
  public userList: AvatarUser[] = []

  constructor(private profileService: ProfileService, private flywheelConfig: FlywheelConfig) { }

  ngOnInit(): void {
    if (this.hideCurrentUser) {
      this.profileService.getProfile().subscribe(profile => {
        const profileId = profile && profile._id;
        this.userList = this.userList.filter(user => user._id !== profileId);
      });
    }
    // wait until parent column header renders
    setTimeout(() => {
      this.calculateDisplay();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  calculateDisplay(): void {
    const container = document.getElementById('usersList');
    if (container) {
      this.avatarMax = Math.floor((container.offsetWidth - 30) / 30 ) - 1;
    }
    this.extraUserString = this.userList.slice(this.avatarMax, 25).reduce(
      (userString, { info }) => userString + `${info}\n`, '');
  }
}
