import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { downgradeComponent } from '@angular/upgrade/static';

import { AdvancedQueryComponent } from './advanced-query/advanced-query.component';
import { FlyqlEditorComponent } from './flyql-editor/flyql-editor.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
  ],
  declarations: [
    AdvancedQueryComponent,
    FlyqlEditorComponent,
  ],
  entryComponents: [
    AdvancedQueryComponent,
  ],
})
export class SearchModule { }

declare const angular: angular.IAngularStatic;
angular.module('search', [])
  .directive(
    'advancedQuery',
    downgradeComponent({ component: AdvancedQueryComponent }) as angular.IDirectiveFactory
  );
