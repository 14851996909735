
    <div class="component-container">
      <mat-autocomplete #autoSuggest="matAutocomplete" autoActiveFirstOption
        (optionSelected)="restoreCursor()" panelWidth="auto">
        <mat-option *ngFor="let suggestion of suggestions$ | async" [value]="suggestion.query">
          {{ suggestion.display }}
        </mat-option>
      </mat-autocomplete>
      <mat-form-field appearance="outline" class="query-string">
        <textarea #queryTextarea matInput [formControl]="queryControl"
          [matAutocomplete]="autoSuggest" (keyup)="saveCursor()" (mouseup)="saveCursor()"></textarea>
      </mat-form-field>
    </div>
  