// data should be fully encoded e.g.
// data:text/plain;charset=utf-8,mycontent
export function downloadFile(filename: string, data: string): void {
  var element = document.createElement('a');
  element.setAttribute('href', data);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}
