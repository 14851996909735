import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'confirm-dialog',
  template: `
    <h1 mat-dialog-title *ngIf="title">{{title}}</h1>
    <div mat-dialog-content>{{content}}</div>
    <div mat-dialog-actions align="center">
      <button mat-button mat-dialog-close>No</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
    </div>
  `,
})
export class ConfirmDialog {
  public title: string
  public content: string = 'Are you sure?'
  constructor(@Inject(MAT_DIALOG_DATA) data: {title?: string, content?: string}) {
    if (data) {
      this.title = data.title;
      this.content = data.content;
    }
  }
}
