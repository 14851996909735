import { FormGroup, FormControl } from '@angular/forms';

import { SearchField } from '../../shared/models/search/search-field.model';

export interface VisualRule {
  field: SearchField
  operator: string
  text: string
  chips: string[]
  boolean: boolean
  number: {
    min: number
    max: number
  }
  date: {
    min: Date
    max: Date
  }
}

export interface VisualGroup {
  connective: 'and' | 'or'
  rules: VisualRule[]
}

export const ruleFormGroup = (rule: Partial<VisualRule> = {
  field: { name: '', type: 'string', facet: false },
  number: { min: null, max: null },
  date: { min: null, max: null },
}): FormGroup => new FormGroup({
  field: new FormControl(rule.field.name),
  operator: new FormControl(rule.operator),
  text: new FormControl(rule.text || ''),
  chips: new FormControl(rule.chips || []),
  boolean: new FormControl(rule.boolean !== false), // default true
  number: new FormGroup({
    min: new FormControl(rule.number.min),
    max: new FormControl(rule.number.max),
  }),
  date: new FormGroup({
    min: new FormControl(rule.date.min),
    max: new FormControl(rule.date.max),
  }),
});

export interface SelectOption {
  value: string
  label?: string
}

const operatorOptions: {[type: string]: SelectOption[]} = {
  string: [
    { value: 'is' },
    { value: '!is', label: 'is not' },
    { value: 'contains' },
    { value: '!contains', label: 'does not contain' },
    { value: 'exists' },
  ],
  number: [
    { value: 'range' },
    { value: 'exists' },
  ],
  boolean: [
    { value: 'is' },
    { value: 'exists' },
  ],
  date: [
    { value: 'range' },
    { value: 'exists' },
  ],
};

export function getOperatorOptions(type: string): SelectOption[] {
  switch (type) {
    case 'boolean':
      return operatorOptions.boolean;
    case 'long':
    case 'integer':
    case 'short':
    case 'byte':
    case 'double':
    case 'float':
    case 'half_float':
    case 'scaled_float':
      return operatorOptions.number;
    case 'date':
      return operatorOptions.date;
    default:
      return operatorOptions.string;
  }
}

export function buildQueryString(queryGroups: VisualGroup[], outerConnective = 'and'): string {
  const groupStrings = queryGroups.map(group => {
    const connective = group.connective.toUpperCase();
    const rules = group.rules.map(buildRuleString).filter(Boolean).join(` ${connective} `);
    return `(${rules})`;
  }).filter(group => group !== '()');
  if (groupStrings.length === 1) {
    return groupStrings[0].slice(1, -1); // trim the parentheses around the only group
  }
  return groupStrings.join(` ${outerConnective.toUpperCase()} `);
}

export function buildRuleString(rule: VisualRule): string {
  const { field: { name, type }, operator } = rule;
  if (!name) {
    return '';
  }
  if (operator === 'exists') {
    return rule.boolean
      ? `${name} EXISTS`
      : `NOT ${name} EXISTS`;
  }
  if (type === 'boolean') { // operator = is
    return `${name} = ${rule.boolean}`;
  }
  if (operator === 'range') {
    const [min, max] = getMinMax(rule);
    return [
      min ? `${name} >= ${min}` : null,
      max ? `${name} <= ${max}` : null,
    ].filter(Boolean).join(' AND ');
  }
  switch (operator) {
    case 'is':
      return rule.chips.length > 1
        ? `${name} IN [${rule.chips.join(', ')}]`
        : `${name} = ${rule.chips[0] || ''}`;
    case '!is':
      return rule.chips.length > 1
        ? `NOT ${name} IN [${rule.chips.join(', ')}]`
        : `${name} != ${rule.chips[0] || ''}`;
    case 'contains':
      return `${name} CONTAINS ${rule.text}`;
    case '!contains':
      return `NOT ${name} CONTAINS ${rule.text}`;
    default:
      return '';
  }
}

function getMinMax(rule: VisualRule): [string, string] {
  if (rule.field.type === 'date') {
    return [
      rule.date.min instanceof Date ? rule.date.min.toISOString() : '',
      rule.date.max instanceof Date ? rule.date.max.toISOString() : '',
    ];
  }
  return [
    typeof rule.number.min === 'number' ? String(rule.number.min) : '',
    typeof rule.number.max === 'number' ? String(rule.number.max) : '',
  ];
}
