import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import APP_MODULE from '../app.module.ajs';
import { FlywheelService } from '../flywheel.service';
import { ContainerAccess, RoleMatrix } from '../shared/enums/container-access.enum';
import { RequestState, isInitialized, isLoading } from '../shared/enums/request-state.enum';
import { Permission } from '../shared/models/permission.model';
import { Profile } from '../shared/models/profile.model';
import { User } from '../shared/models/user.model';
import { Version } from '../shared/models/version.model';
import { SiteSettings } from '../shared/models/site-settings.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private profile = {
    state: RequestState.Empty,
    subject: new BehaviorSubject<Profile>(undefined),
  }
  readonly profile$ = this.profile.subject.asObservable()

  private version = {
    state: RequestState.Empty,
    subject: new BehaviorSubject<Version>(undefined),
  }
  readonly version$ = this.version.subject.asObservable()

  private siteSettings = {
    state: RequestState.Empty,
    subject: new ReplaySubject<SiteSettings>(undefined),
  }
  readonly siteSettings$ = this.siteSettings.subject.asObservable()

  private error = new Subject<Error>()
  readonly error$ = this.error.asObservable()

  constructor(private flywheel: FlywheelService) { }

  getProfile({ refresh = false } = {}): Observable<Profile> {
    if (isLoading(this.profile.state)) {
      return this.profile$;
    }
    if (!isInitialized(this.profile.state) || refresh) {
      this.profile.state = RequestState.Fetching;
      this.flywheel.users.self().subscribe(data => {
        this.profile.subject.next(data as Profile);
      }, error => {
        this.error.next(error);
      }, () => {
        this.profile.state = RequestState.Loaded;
      });
    }
    return this.profile$;
  }

  getRoleMatrix(container: {permissions: Permission[]}): RoleMatrix {
    const profile = this.profile.subject.value;
    if (!profile) {
      throw new Error('User profile has not been loaded yet');
    }
    let maxAccess = 0;
    if (profile.root) {
      maxAccess = ContainerAccess.valueOf(ContainerAccess.Admin);
    } else {
      maxAccess = Math.max(
        ...(container.permissions || [])
          .filter(({ _id }) => _id === profile._id)
          .map(({ access }) => ContainerAccess.valueOf(access))
      );
    }
    return ContainerAccess.roleMatrix(maxAccess);
  }

  generateApiKey(): Observable<string> {
    return this.flywheel.users.generateKey().pipe(
      map(({ key }) => key)
    );
  }

  getVersion({ refresh = false } = {}): Observable<Version> {
    if (isLoading(this.version.state)) {
      return this.version$;
    }
    if (!isInitialized(this.version.state) || refresh) {
      this.version.state = RequestState.Fetching;
      this.flywheel.version().subscribe(data => {
        this.version.subject.next(data);
      }, error => {
        this.error.next(error);
      }, () => {
        this.version.state = RequestState.Loaded;
      });
    }
    return this.version$;
  }
  
  getSiteSettings({ refresh = false } = {}): Observable<SiteSettings> {
    if (isLoading(this.siteSettings.state)) {
      return this.siteSettings$;
    }
    if (!isInitialized(this.siteSettings.state) || refresh) {
      this.siteSettings.state = RequestState.Fetching;
      this.flywheel.siteSettings.get().subscribe(data => {
        this.siteSettings.subject.next(data);
      }, error => {
        this.error.next(error);
      }, () => {
        this.siteSettings.state = RequestState.Loaded;
      })
    }
    return this.siteSettings$;
  }

  setSiteSettings(body: Partial<SiteSettings>): Observable<any> {
    const observable = this.flywheel.siteSettings.put(body).pipe(
      shareReplay(1)
    );
    observable.subscribe(() => {
      this.getSiteSettings({ refresh: true });
    }, () => {});
    return observable;
  }

  logout() {
    this.profile.state = RequestState.Empty;
    this.profile.subject.next(null);
  }
}

declare const angular: angular.IAngularStatic;
angular.module(APP_MODULE)
  .factory(
    'profileService',
    downgradeInjectable(ProfileService)
  );

export function getAvatarColor(user: User): string {
  return intToRGB(hashCode(user._id));
}

function intToRGB(i: number): string {
  var c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  return '00000'.substring(0, 6 - c.length) + c;
}

function hashCode(str: string): number {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}
