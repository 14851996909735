import './polyfills';
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import './index.ajs.js'; // define AngularJS modules
import { getApiConfig } from './src/app.module.ajs'; // AngularJS module
import { AppModule } from './src/app.module'; // Angular module

if (process.env.NODE_ENV !== 'development') {
  enableProdMode();
}

getApiConfig().then(() => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
