/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Device } from './shared/models/device.model';
import { Group } from './shared/models/group.model';
import { Project } from './shared/models/project.model';
import { QueryValidation } from './shared/models/search/query-validation.model';
import { SearchFacets } from './shared/models/search/search-facets.model';
import { SearchField } from './shared/models/search/search-field.model';
import { SearchQuery } from './shared/models/search/search-query.model';
import { SearchResultResponse } from './shared/models/search/search-results.model';
import { SearchStatus } from './shared/models/search/search-status.model';
import { SearchSuggestionResponse } from './shared/models/search/search-suggestions.model';
import { User } from './shared/models/user.model';
import { Version } from './shared/models/version.model';
import { SiteSettings } from './shared/models/site-settings.model';

export interface ApiParams {
  [param: string]: string | number | boolean
  filter?: string
  sort?: string
  limit?: number
  skip?: number
  page?: number
  after_id?: string
}

export interface WriteResult {
  deleted?: number
  modified?: number
}

@Injectable({
  providedIn: 'root',
})
export class FlywheelService {
  public dataexplorer = {
    facets: (body: Partial<SearchQuery>) => this.post<{facets: SearchFacets}>('/api/dataexplorer/facets', body),
    fields: (body: {field: string}) => this.post<SearchField[]>('/api/dataexplorer/search/fields', body),
    parse: (body: Partial<SearchQuery>) => this.post<QueryValidation>('/api/dataexplorer/search/parse', body),
    search: (params: ApiParams, body: SearchQuery) => this.post<SearchResultResponse>('/api/dataexplorer/search', body, { params: createParams(params) }),
    status: () => this.get<SearchStatus>('/api/dataexplorer/search/status'),
    suggest: (body: Partial<SearchQuery>) => this.post<SearchSuggestionResponse>('/api/dataexplorer/search/suggest', body),
    training: (body: any) => this.post<any>('/api/dataexplorer/search/training', body),
  }

  public devices = {
    query: (params: ApiParams = {}) => this.get<Device[]>('/api/devices', params),
    post: (body: Partial<Device>) => this.post<Device>('/api/devices', body),
    get: (deviceId: string) => this.get<Device>(`/api/devices/${deviceId}`),
    put: (deviceId: string, body: Partial<Device>) => this.put<null>(`/api/devices/${deviceId}`, body),
    generateKey: (deviceId: string) => this.post<{key: string}>(`/api/devices/${deviceId}/key`),
  }

  public groups = {
    query: (params: ApiParams = {}) => this.get<Group[]>('/api/groups', params),
    post: (body: Partial<Group>) => this.post<Group>('/api/groups', body),
    get: (groupId: string) => this.get<Group>(`/api/groups/${groupId}`),
    put: (groupId: string, body: Partial<Group>) => this.put<WriteResult>(`/api/groups/${groupId}`, body),
    delete: (groupId: string) => this.delete<WriteResult>(`/api/groups/${groupId}`),
    projects: (groupId: string, params: ApiParams) => this.get<Project[]>(`/api/groups/${groupId}`, params),
  }

  public projects = {
    query: (params: ApiParams = {}) => this.get<Project[]>('/api/projects', params),
    post: (body: Partial<Project>) => this.post<Project>('/api/projects', body),
    get: (projectId: string) => this.get<Project>(`/api/projects/${projectId}`),
    put: (projectId: string, body: Partial<Project>) => this.put<WriteResult>(`/api/projects/${projectId}`, body),
    delete: (projectId: string) => this.delete<WriteResult>(`/api/projects/${projectId}`),
  }

  public siteSettings = {
    get: () => this.get<SiteSettings>('/api/site/settings'),
    put: (body: Partial<SiteSettings>) => this.put<WriteResult>('/api/site/settings', body),
  }

  public users = {
    query: (params: ApiParams = {}) => this.get<User[]>('/api/users', params),
    get: (userId: string) => this.get<User>(`/api/users/${userId}`),
    put: (userId: string, body: Partial<User>) => this.put<WriteResult>(`/api/users/${userId}`, body),
    post: (body: Partial<User>) => this.post<User>(`/api/users`, body),
    delete: (userId: string) => this.delete<WriteResult>(`/api/users/${userId}`),
    self: () => this.get<User>('/api/users/self'),
    generateKey: () => this.post<{key: string}>('/api/users/self/key'),
  }

  public version = () => this.get<Version>('/api/version')

  constructor(private http: HttpClient) { }

  get<T>(url: string, params?: ApiParams, options = {}): Observable<T> {
    return this.http.get<T>(url, { ...options, params: createParams(params) });
  }

  put<T>(url: string, body?: any, options?: object): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  patch<T>(url: string, body?: any, options?: object): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  post<T>(url: string, body?: any, options?: object): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  delete<T>(url: string, options?: object): Observable<T> {
    return this.http.delete<T>(url, options);
  }
}

export function createParams(apiParams: ApiParams = {}): HttpParams {
  return Object.entries(apiParams).reduce((params, [key, value]) => {
    return params.set(key, String(value));
  }, new HttpParams());
}

export function createHeaders(headers: {[name: string]: string | string[]}): HttpHeaders {
  return new HttpHeaders(headers);
}
