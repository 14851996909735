
    <mat-form-field appearance="outline">
      <input matInput
        [(formControl)]="control" 
        [placeholder]="placeholder" 
        [required]="required">
      <mat-error>
        {{ errorMessage }}
      </mat-error>
    </mat-form-field>
  