
    <h1 mat-dialog-title>Add New Device</h1>
    <div mat-dialog-content>
      <form #form="ngForm">
        <label class="field">
          <span class="mat-body-strong">Label</span>
          <mat-form-field appearance="outline">
            <input data-id="new-device-label" matInput name="label" [(ngModel)]="label" required>
            <mat-error>Label is required</mat-error>
          </mat-form-field>
        </label>
        <label class="field">
          <span class="mat-body-strong">Type</span>
          <mat-form-field appearance="outline">
            <mat-select data-id="new-device-type" name="type" [(ngModel)]="type">
              <mat-option value="reaper">Reaper</mat-option>
              <mat-option value="importer">Importer</mat-option>
            </mat-select>
          </mat-form-field>
        </label>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button data-id="device-modal-close" mat-button (click)="onClose()">Close</button>
      <button data-id="device-modal-add" mat-raised-button (click)="onAdd()" color="primary" cdkFocusInitial>Add</button>
    </div>
  