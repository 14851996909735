import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { AdminService } from '../admin.service';
import { NotificationService } from '../../ajs-upgraded-providers';
import { DeviceType } from '../../shared/models/device.model';

@Component({
  selector: 'add-device',
  template: `
    <h1 mat-dialog-title>Add New Device</h1>
    <div mat-dialog-content>
      <form #form="ngForm">
        <label class="field">
          <span class="mat-body-strong">Label</span>
          <mat-form-field appearance="outline">
            <input data-id="new-device-label" matInput name="label" [(ngModel)]="label" required>
            <mat-error>Label is required</mat-error>
          </mat-form-field>
        </label>
        <label class="field">
          <span class="mat-body-strong">Type</span>
          <mat-form-field appearance="outline">
            <mat-select data-id="new-device-type" name="type" [(ngModel)]="type">
              <mat-option value="reaper">Reaper</mat-option>
              <mat-option value="importer">Importer</mat-option>
            </mat-select>
          </mat-form-field>
        </label>
      </form>
    </div>
    <div mat-dialog-actions align="end">
      <button data-id="device-modal-close" mat-button (click)="onClose()">Close</button>
      <button data-id="device-modal-add" mat-raised-button (click)="onAdd()" color="primary" cdkFocusInitial>Add</button>
    </div>
  `,
  styles: [`
    .mat-dialog-content {
      overflow: visible;
    }
    .field {
      display: block;
      margin-bottom: 15px;
    }
    .field > span {
      display: inline-block;
      width: 100px;
      vertical-align: middle;
    }
    .field > mat-slide-toggle {
      margin-right: 32px;
      vertical-align: middle;
    }
  `],
})
export class AddDeviceComponent {
  public label = ''
  public type: DeviceType = 'reaper'

  @ViewChild('form') form: NgForm

  constructor(
    private admin: AdminService,
    private dialogRef: MatDialogRef<AddDeviceComponent>,
    private notification: NotificationService,
  ) { }

  onClose(): void {
    this.dialogRef.close();
  }

  onAdd(): void {
    for (const control of Object.values(this.form.controls)) {
      control.markAsTouched();
    }
    if (!this.label) {
      return;
    }
    this.admin.addDevice({
      type: this.type,
      label: this.label,
    }).subscribe(device => {
      this.notification.success('device.add.success');
      this.dialogRef.close(device);
    }, () => {
      this.notification.error('device.add.error');
    });
  }
}
