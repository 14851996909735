export enum RequestState {
  Empty,    // No data is available yet
  Fetching, // Data is being loaded from the server
  Loaded,   // Data is available
  Updating, // Data is being refreshed from the server
  Saving,   // Data is being persisted to the server
}

export function isInitialized(state: RequestState): boolean {
  return state >= RequestState.Loaded;
}

export function isLoading(state: RequestState): boolean {
  return state === RequestState.Fetching || state === RequestState.Updating;
}
