interface CoerceFunction {
  (value: string): any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const identity: CoerceFunction = (value: string): string => value;

export function paramsToQueryString(params = {}): string {
  const query = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
  return query ? `?${query}` : '';
}

export function queryStringToParams(query = '', coerce: CoerceFunction = identity): object {
  if (!query) {
    return {};
  }
  if (query[0] === '?') {
    query = query.slice(1);
  }
  return query.split('&').reduce((params, keyval) => {
    const [key, value = 'true'] = keyval.split('=');
    return { ...params, [key]: coerce(value) };
  }, {});
}
