<header>
  <h1 class="font-page-header">Advanced Search</h1>
</header>
<form [formGroup]="queryForm">
  <!-- Shared autocomplete for fields -->
  <mat-autocomplete #autoField="matAutocomplete" panelWidth="auto">
    <mat-option *ngFor="let field of fields$ | async" [value]="field.name">
      {{ field.name }}
    </mat-option>
  </mat-autocomplete>
  <!-- Shared autocomplete for values -->
  <mat-autocomplete #autoValue="matAutocomplete" (optionSelected)="selectChip($event)" panelWidth="auto" >
    <mat-option *ngFor="let suggestion of suggestions$ | async" [value]="suggestion.value">
      {{ suggestion.display }}
    </mat-option>
  </mat-autocomplete>

  <ng-container formArrayName="queryGroups">
    <div *ngFor="let group of queryForm.get('queryGroups')['controls']; index as groupIndex" [formGroupName]="groupIndex" class="group">
      <!-- Connective -->
      <mat-button-toggle-group formControlName="connective">
        <mat-button-toggle value="and">AND</mat-button-toggle>
        <mat-button-toggle value="or">OR</mat-button-toggle>
      </mat-button-toggle-group>

      <mat-list formArrayName="rules" role="list">
        <mat-list-item *ngFor="let rule of group.get('rules')['controls']; index as ruleIndex" [formGroupName]="ruleIndex" role="listitem">

          <!-- Field -->
          <mat-form-field appearance="outline" class="inline-field">
            <input #field type="text" matInput formControlName="field"
              placeholder="Field"
              [matAutocomplete]="autoField"
              (focus)="bindFieldAutocomplete(rule)">
          </mat-form-field>

          <!-- Operator -->
          <mat-form-field appearance="outline" class="inline-field">
            <mat-select #operator formControlName="operator" placeholder="Operator">
              <mat-option *ngFor="let option of getOperatorOptions(rule)" [value]="option.value">{{ option.label || option.value }}</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Value -->
          <div [ngSwitch]="getValueInterface(rule)" class="inline-field grow-field">
            <!-- Chips -->
            <mat-form-field *ngSwitchCase="'chips'" appearance="outline" class="chip-field">
              <mat-chip-list #chipList formControlName="chips" aria-label="Query field value">
                <mat-chip *ngFor="let value of rule.get('chips').value; index as chipIndex" class="chip"
                  [disabled]="queryForm.disabled"
                  (removed)="removeChip(rule, chipIndex)">
                  {{value}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  #chipInput
                  formControlName="text"
                  placeholder="Value(s)"
                  [disabled]="queryForm.disabled"
                  (focus)="bindSuggestionAutocomplete(rule)"
                  [matAutocomplete]="autoValue"
                  [matChipInputFor]="chipList"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="addChip(rule, $event)">
              </mat-chip-list>
            </mat-form-field>

            <!-- Boolean -->
            <mat-form-field *ngSwitchCase="'boolean'" appearance="outline">
              <mat-select formControlName="boolean">
                <mat-option [value]="true">True</mat-option>
                <mat-option [value]="false">False</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Range -->
            <ng-container *ngSwitchCase="'range'" formGroupName="number">
              <mat-form-field appearance="outline" class="inline-field short-field">
                <input type="number" matInput formControlName="min" placeholder="Min">
              </mat-form-field>
              <span class="inline-field">&#8211;</span>
              <mat-form-field appearance="outline" class="short-field">
                <input type="number" matInput formControlName="max" placeholder="Max">
              </mat-form-field>
            </ng-container>

            <!-- Date -->
            <ng-container *ngSwitchCase="'date'" formGroupName="date">
              <mat-form-field appearance="outline" class="inline-field short-field">
                <input type="string" matInput formControlName="min" [matDatepicker]="minPicker" placeholder="Start" class="datepicker-input">
                <mat-datepicker-toggle matSuffix [for]="minPicker" class="datepicker-toggle"></mat-datepicker-toggle>
                <mat-datepicker #minPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="short-field">
                <input type="string" matInput formControlName="max" [matDatepicker]="maxPicker" placeholder="End" class="datepicker-input">
                <mat-datepicker-toggle matSuffix [for]="maxPicker" class="datepicker-toggle"></mat-datepicker-toggle>
                <mat-datepicker #maxPicker></mat-datepicker>
              </mat-form-field>
            </ng-container>

            <!-- Input -->
            <mat-form-field *ngSwitchDefault appearance="outline">
              <input type="string" matInput formControlName="text" placeholder="Value"
                [matAutocomplete]="autoValue"
                (focus)="bindSuggestionAutocomplete(rule)">
            </mat-form-field>
          </div>

          <button mat-raised-button color="primary" (click)="resetRule(rule)" [disabled]="queryForm.disabled" class="inline-field">Clear</button>
          <button *ngIf="canRemoveRule" type="button" mat-raised-button color="primary" (click)="removeRule(groupIndex, ruleIndex)" [disabled]="queryForm.disabled">Remove</button>
        </mat-list-item>
      </mat-list>

      <button type="button" mat-raised-button color="primary" (click)="addRule(group)" [disabled]="queryForm.disabled" class="add-rule-button">Add term</button>
    </div>
  </ng-container>

  <button type="button" mat-raised-button color="primary" (click)="addGroup()" [disabled]="queryForm.disabled" class="add-group-button">Add group</button>
  <button type="button" mat-raised-button color="primary" (click)="exitManualMode()" [disabled]="!queryForm.disabled">Visual Mode</button>

  <flyql-editor [(value)]="queryString" (valueChange)="enterManualMode()"></flyql-editor>

  <button type="button" mat-raised-button color="primary" (click)="runQuery()" class="query-button">Run Query</button>
</form>
